import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, FormGroup } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import Select, { components } from "react-select";
import { connect } from "react-redux";
import { useHistory, useParams, useLocation , Link } from "react-router-dom";
import CustomButton from "@components/custom-button/CustomButton";
import InputField from "@components/input-field/InputField";
import MainFooter from "@components/footer/MainFooter";
import Loader from "@components/full-screen-loader/FullScreenLoader";
import BookingSuccessPopup from "@components/booking-success-popup/BookingSuccessPopup";
import Navbar from "@components/navbar/Navbar";
import BookingForm from "@components/booking-form/BookingForm";
import { ReactComponent as CalendarIcon } from "@images/calender.svg";
import { ReactComponent as ClockIcon } from "@images/clock.svg";
import { ReactComponent as LocationIcon } from "@images/location.svg";
import { ReactComponent as CaretDownIcon } from "@images/dropdown.svg";
import banner from "@images/banner.png";
import "@features/tour-booking/TourBookingStyle.scss";
import {
  clearBackendError,
  resetTourBooking,
  clearCouponResult,
} from "@store/eventredux";
import moment from "moment";
import BigNumber from "bignumber.js";
import { isNumber } from "lodash";
import Razorpay from "razorpay";
import * as _ from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isSameDay } from "date-fns";
const BN = require("bn.js");

function TourBooking({
  GetEventDetail,
  eventDetails,
  eventLoading,
  faqs,
  faqsLoading,
  faqsError,
  GetFaqs,
  userLoggedIn,
  eventError,
  clearBackendErrorAsync,
  clearCouponCodeAsync,
  userDet,
  refreshBookingState,
  tourBookLoading,
  tourSuccess,
  tourError,
  TourBookAsync,
  ValidateCouponCodeAsync,
  validateCouponCodeLoading,
  validateCouponCodeResult,
  validateCouponCodeError,
}) {
  const [bookingDetails, setBookingDetails] = useState();
  const [checked, setChecked] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [maxNumOfTravellaers, setMaxNumOfTravellaers] = useState("20");
  const [counter, setCounter] = useState(0);
  let history = useHistory();

  const [departureTime, setDepartureTime] = useState([]);
  const [selectedTime, setSelectedTime] = useState("");

  const [success, setSuccess] = useState(false);
  let { id } = useParams();
  var para;
  const location2 = useLocation();
  //console.log("loc", location2.pathname);
  let params = () => {
    let arr = location2.pathname.split("/");
    para = arr[2];

    return para;
  };

  const [backendError, setBackendError] = useState();
  const [dateExceeded, setDateExceeded] = useState(0);
  const [banner, setBanner] = useState("");
  const [title, setTitle] = useState("");
  const [tourRef, setTourRef] = useState("");
  const [location, setLocation] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [description, setdescription] = useState("");
  const [ticketSaleStartdate, setTicketSaleStartDate] = useState();
  const [ticketSaleEndDate, setTicketSaleEndDate] = useState();
  const [maxGuest, setMaxGuest] = useState(0);
  const [calTotal, setCalTotal] = useState();

  const [accessToken, setAccessToken] = useState("");

  const [slider, setSlider] = useState();
  const [availabilityDates, setAvailbilityDates] = useState();
  const [amount, setAmount] = useState();
  const [dropdownDates, setDropDownDates] = useState();
  const [dateForBackend, setDateForBackend] = useState();
  const [showValue, setShowValue] = useState();
  const [coupon, setCoupon] = useState("");
  const [codeEntered, setCodeEntered] = useState(false);
  const [eveId, setEveId] = useState();
  const [disableButton, setDisableButton] = useState(false);
  // const params = new URLSearchParams(window.location.search);
  const [myRedirect, setMyRedirect] = useState("");
  const [popupForGuest, setPopupForGuest] = useState(false);

  let emailPattern =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  var re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  const [person, setPerson] = useState({
    userName: "",
    email: "",
    mobile: "",
  });

  const [dates, setDates] = useState([]);

  const [selectedDate, setSelectedDate] = useState(null);
  useEffect(() => {
    // console.log("id", id);
    if (id === undefined || isNaN(id) === true) {
      params();
    }
  }, [location2, id]);
  useEffect(() => {
    if (para !== undefined) {
      setMyRedirect(para);
    }
  }, [para]);
  useEffect(() => {
    if (myRedirect !== "") {
      GetEventDetail({
        urlSlug: true,
        slugVal: myRedirect,
      });
    } else {
      if (id !== undefined && isNaN(id) === false) {
        GetEventDetail({ urlSlug: false, eventId: id });
      }
    }
  }, [myRedirect, id]);
  const handleBackendCallsBasedOnIdChange = () => {
    if (myRedirect !== "" && eveId !== undefined) {
      // getEventTickets({ id: eveId });
      // GetEventDetail({ eventId: eveId });
      GetFaqs({ eventId: eveId });
    } else {
      if (id !== undefined) {
      }
    }
  };
  useEffect(() => {
    handleBackendCallsBasedOnIdChange();
  }, [id, eveId]);

  const handleTimeChange = (e) => {
    
    const selectedTimeVal = e.target.value;
    const selectedTimeObj = departureTime.find(
      (timeObj) => timeObj.timeVal === selectedTimeVal
    );
    setDateForBackend(selectedTimeObj ? selectedTimeObj.idOfTime : undefined);
    console.log("selected time obje", selectedTimeObj);
    setMaxGuest(selectedTimeObj ? selectedTimeObj.numOfGuests : 0);
  };

  useEffect(() => {
    console.log("selectedtime", selectedTime);
  }, [selectedTime]);

  const getTotalValue = () => {
    if (
      amount !== undefined &&
      counter > 0 &&
      eventDetails !== undefined &&
      eventDetails.gstPercentage !== null
    ) {
      return (
        (amount * counter * Number(eventDetails.gstPercentage)) / 100 +
        amount * counter
      ).toFixed(2);
    } else {
      if (amount !== undefined && counter > 0 && eventDetails !== undefined) {
        return (amount * counter).toFixed(2);
      } else {
        return 0;
      }
    }
  };

  useEffect(() => {
    if (validateCouponCodeResult !== undefined) {
      //console.log("Result from frontend -> ", validateCouponCodeResult);
    }
  }, [validateCouponCodeResult]);
  useEffect(() => {
    refreshBookingState();
    setShowSuccessPopup(false);
    return () => {
      refreshBookingState();
    };
  }, []);
  // useEffect(() => {
  //   if (userLoggedIn === undefined && id) {
  //     history.push(`/tourdetails/${id}`);
  //   }
  // }, []);
  // useEffect(() => {
  //   // clearBackendErrorAsync();
  //   GetEventDetail({ eventId: id });
  //   GetFaqs({ eventId: id });
  //   // GetVillaDetails({ id });
  //   // GetVillaFacilities({ id });
  // }, [id]);

  useEffect(() => {
    if (eventDetails !== undefined) {
      // console.log("ev", eventDetails);
      setBanner(eventDetails.banner);
      setTitle(eventDetails.title);
      setTourRef(eventDetails.tourRef);
      setLocation(eventDetails.location);
      setStartDate(eventDetails.startDate);
      setEndDate(eventDetails.endDate);
      if (
        eventDetails !== undefined &&
        eventDetails.eventImages !== undefined &&
        eventDetails.eventImages.length > 0
      ) {
        setSlider(eventDetails.eventImages);
      }
      setEveId(eventDetails.id);
      setdescription(eventDetails.description);
      setAmount(eventDetails.amount);
      setAvailbilityDates(eventDetails.availabilities);
      // setMaxGuest(eventDetails.available);
      // checkExceedDataTime(eventDetails.endDate);
      // setTicketSaleStartDate(eventDetails.ticketSaleStartDate);
      // setTicketSaleEndDate(eventDetails.ticketSaleEndDate);
    }
  }, [eventDetails, id, eveId]);

  useEffect(() => {
    if (eventError !== undefined) {
      setBackendError(eventError);
    }
  }, [eventError]);

  useEffect(() => {
    if (userLoggedIn !== undefined) {
      //console.log("user", userLoggedIn);
      setAccessToken(userLoggedIn.accessToken);
    }
  }, [userLoggedIn]);

  useEffect(() => {
    setDisableButton(coupon !== "");
  }, [coupon]);
  const handleCouponCodeClear = () => {
    clearCouponCodeAsync();
    setCoupon("");
  };
  useEffect(() => {
    if (validateCouponCodeError) setCodeEntered(true);
  }, [validateCouponCodeError]);

  const handleCheckButton = (e) => {
    setChecked(!checked);
  };

  const increase = () => {
    if (counter <= maxGuest) {
      setCounter((count) => count + 1);
    }
  };

  const decrease = () => {
    if (counter > 0) {
      setCounter((count) => count - 1);
    }
  };
  useEffect(() => {
    if (availabilityDates !== undefined && availabilityDates.length > 0) {
      setDropDownDates(dateFormatter());
      setDates(availabilityDates);
    }
  }, [availabilityDates]);
  const dateFormatter = () => {
    let finalArray = [];
    if (availabilityDates !== undefined && availabilityDates.length > 0) {
      for (let i = 0; i < availabilityDates.length; i++) {
        let formatted = moment(availabilityDates[i].eventStart).format(
          "DD/MM/YY hh:mm A"
        );
        let decidingParam = moment(formatted, "DD/MM/YYYY hh:mm A").isBefore(
          moment(new Date(), "DD/MM/YYYY hh:mm A"),
          "day"
        );

        if (
          decidingParam === false &&
          availabilityDates[i].available > 0 &&
          availabilityDates[i].deletedOn === null
        ) {
          let data = {
            label: formatted,
            value: availabilityDates[i].id,
          };
          finalArray.push(data);
        }
      }
      return finalArray;
    }
  };

  const availableDates = dates?.map(
    (date) => new Date(date.available !== 0 && date.eventStart)
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
    let times = [];
    const selectedDateUTC = new Date(date).toUTCString();

    const selectedDateObject = dates.filter((dateObj) =>
      isSameDay(new Date(dateObj.eventStart), new Date(selectedDateUTC))
    );
    console.log("selexcted", selectedDateObject);
    if (selectedDateObject) {
      // setDateForBackend(selectedDateObject[0].id);
      for (let i = 0; i < selectedDateObject.length; i++) {
        times.push({
          idOfTime: selectedDateObject[i].id,
          timeVal: formatDepartureTime(selectedDateObject[i].eventStart),
          numOfGuests: selectedDateObject[i].available,
        });
      }
      if (selectedDateObject.length == 1) {
        setSelectedTime(times[0].timeVal);
        setDateForBackend(times[0].idOfTime);
        setMaxGuest(times[0].numOfGuests); 
      }
      setDepartureTime(times);
    }
  };

  const filterDate = (date) => {
    // Convert the selected date to UTC format to match the array dates
    const selectedDateUTC = new Date(date).toUTCString();
    return availableDates.some((availableDate) =>
      isSameDay(new Date(availableDate), new Date(selectedDateUTC))
    );
  };

  const highlightDates = () => {
    let data = dates
      ?.filter((date) => date.available === 0)
      ?.map((date) => new Date(date.eventStart));
    return data;
  };

  const formatDepartureTime = (mydate) => {
    let dateObj = new Date(mydate);
    let finalTime = moment(dateObj).format("hh:mm A");

    return finalTime;
  };

  const renderDayContents = (day, date) => {
    const availableDate = dates?.find((dateObj) =>
      isSameDay(new Date(dateObj.eventStart), new Date(date))
    );

    if (availableDate && availableDate.available === 0) {
      const tooltipText = `No tickets available on this date.`;
      return (
        <div title={tooltipText} className="booked-date">
          {day}
        </div>
      );
    }

    return day;
  };

  const handleGuest = (e) => {
    setPerson({
      ...person,
      [e.target.name]: e.target.value,
    });
  };
  const handlePaymentButtonCall = (guestCalling) => {
    if (checked === false) {
      alert("Please accept our terms and conditions to continue booking!");
    } else if (dateForBackend === undefined) {
      alert("Please select date to continue booking!");
    } else if (counter === 0) {
      alert("Please select number of person(s) to continue booking!");
    } else {
      let data;
      if (
        amount > 0 ||
        Number(
          validateCouponCodeResult !== undefined
            ? validateCouponCodeResult.type === "percent"
              ? validateCouponCodeResult.maximumDiscount !== undefined &&
                validateCouponCodeResult.maximumDiscount !== null &&
                validateCouponCodeResult.maximumDiscount !== 0
                ? (
                    (getTotalValue() * validateCouponCodeResult.value) /
                    100
                  ).toFixed(2) <= validateCouponCodeResult.maximumDiscount
                  ? (
                      getTotalValue() -
                      (
                        (getTotalValue() * validateCouponCodeResult.value) /
                        100
                      ).toFixed(2)
                    ).toFixed(2)
                  : (
                      getTotalValue() - validateCouponCodeResult.maximumDiscount
                    ).toFixed(2)
                : (
                    getTotalValue() -
                    (
                      (getTotalValue() * validateCouponCodeResult.value) /
                      100
                    ).toFixed(2)
                  ).toFixed(2)
              : Number(validateCouponCodeResult.value) > Number(getTotalValue())
              ? (
                  (amount * counter * Number(eventDetails.gstPercentage)) /
                  100
                ).toFixed(2)
              : (getTotalValue() - validateCouponCodeResult.value).toFixed(2)
            : getTotalValue()
        ) !== 0
      ) {
        if (
          validateCouponCodeResult !== undefined &&
          Number(
            validateCouponCodeResult !== undefined
              ? validateCouponCodeResult.type === "percent"
                ? validateCouponCodeResult.maximumDiscount !== undefined &&
                  validateCouponCodeResult.maximumDiscount !== null &&
                  validateCouponCodeResult.maximumDiscount !== 0
                  ? (
                      (getTotalValue() * validateCouponCodeResult.value) /
                      100
                    ).toFixed(2) <= validateCouponCodeResult.maximumDiscount
                    ? (
                        getTotalValue() -
                        (
                          (getTotalValue() * validateCouponCodeResult.value) /
                          100
                        ).toFixed(2)
                      ).toFixed(2)
                    : (
                        getTotalValue() -
                        validateCouponCodeResult.maximumDiscount
                      ).toFixed(2)
                  : (
                      getTotalValue() -
                      (
                        (getTotalValue() * validateCouponCodeResult.value) /
                        100
                      ).toFixed(2)
                    ).toFixed(2)
                : Number(validateCouponCodeResult.value) >
                  Number(getTotalValue())
                ? (
                    (amount * counter * Number(eventDetails.gstPercentage)) /
                    100
                  ).toFixed(2)
                : (getTotalValue() - validateCouponCodeResult.value).toFixed(2)
              : getTotalValue()
          ) !== 0
        ) {
          data = {
            eventAvailabilityId: dateForBackend,
            totalAttendees: counter,
            token: accessToken,
            id: id !== undefined && isNumber(id) ? Number(id) : eveId,
            couponId: validateCouponCodeResult.id,
            guest: false,
          };
        } else if (
          Number(
            validateCouponCodeResult !== undefined
              ? validateCouponCodeResult.type === "percent"
                ? validateCouponCodeResult.maximumDiscount !== undefined &&
                  validateCouponCodeResult.maximumDiscount !== null &&
                  validateCouponCodeResult.maximumDiscount !== 0
                  ? (
                      (getTotalValue() * validateCouponCodeResult.value) /
                      100
                    ).toFixed(2) <= validateCouponCodeResult.maximumDiscount
                    ? (
                        getTotalValue() -
                        (
                          (getTotalValue() * validateCouponCodeResult.value) /
                          100
                        ).toFixed(2)
                      ).toFixed(2)
                    : (
                        getTotalValue() -
                        validateCouponCodeResult.maximumDiscount
                      ).toFixed(2)
                  : (
                      getTotalValue() -
                      (
                        (getTotalValue() * validateCouponCodeResult.value) /
                        100
                      ).toFixed(2)
                    ).toFixed(2)
                : Number(validateCouponCodeResult.value) >
                  Number(getTotalValue())
                ? (
                    (amount * counter * Number(eventDetails.gstPercentage)) /
                    100
                  ).toFixed(2)
                : (getTotalValue() - validateCouponCodeResult.value).toFixed(2)
              : getTotalValue()
          ) === 0
        ) {
          data = {
            eventAvailabilityId: dateForBackend,
            totalAttendees: counter,
            token: accessToken,
            id: id !== undefined && isNumber(id) ? Number(id) : eveId,
            couponId: validateCouponCodeResult.id,
            freeEvent: true,
            guest: false,
          };
        } else {
          data = {
            eventAvailabilityId: dateForBackend,
            totalAttendees: counter,
            token: accessToken,
            id: id !== undefined && isNumber(id) ? Number(id) : eveId,
            guest: false,
          };
        }
      } else if (
        Number(
          validateCouponCodeResult !== undefined
            ? validateCouponCodeResult.type === "percent"
              ? validateCouponCodeResult.maximumDiscount !== undefined &&
                validateCouponCodeResult.maximumDiscount !== null &&
                validateCouponCodeResult.maximumDiscount !== 0
                ? (
                    (getTotalValue() * validateCouponCodeResult.value) /
                    100
                  ).toFixed(2) <= validateCouponCodeResult.maximumDiscount
                  ? (
                      getTotalValue() -
                      (
                        (getTotalValue() * validateCouponCodeResult.value) /
                        100
                      ).toFixed(2)
                    ).toFixed(2)
                  : (
                      getTotalValue() - validateCouponCodeResult.maximumDiscount
                    ).toFixed(2)
                : (
                    getTotalValue() -
                    (
                      (getTotalValue() * validateCouponCodeResult.value) /
                      100
                    ).toFixed(2)
                  ).toFixed(2)
              : Number(validateCouponCodeResult.value) > Number(getTotalValue())
              ? (
                  (amount * counter * Number(eventDetails.gstPercentage)) /
                  100
                ).toFixed(2)
              : (getTotalValue() - validateCouponCodeResult.value).toFixed(2)
            : getTotalValue()
        ) === 0
      ) {
        console.log("here");
        data = {
          eventAvailabilityId: dateForBackend,
          totalAttendees: counter,
          token: accessToken,
          id: id !== undefined && isNumber(id) ? Number(id) : eveId,
          freeEvent: true,
          guest: false,
        };
      } else {
        data = {
          eventAvailabilityId: dateForBackend,
          totalAttendees: counter,
          token: accessToken,
          id: id !== undefined && isNumber(id) ? Number(id) : eveId,
          guest: false,
          // freeEvent: true,
        };
      }
      if (guestCalling === true) {
        let withGuestData = {
          bookingDetails: data,
          userName: person.userName,
          email: person.email,
          mobile: person.mobile,
          id: id !== undefined && isNumber(id) ? Number(id) : eveId,
          guest: true,
        };
        //   console.log("guest", withGuestData);
        TourBookAsync(withGuestData);
      } else {
        //    console.log("guest", data);
        TourBookAsync(data);
      }
    }
  };

  useEffect(() => {
    console.log('tourSucess' , tourSuccess);
    if (tourSuccess !== undefined && tourSuccess.booking !== undefined ) {
      if (Number(tourSuccess.booking.totalAmount) > 0) {
        console.log(
          "insideee toursucess",
          Number(tourSuccess.booking.totalAmount) > 0
        );
        setBookingDetails(tourSuccess);
        razorPayCall(tourSuccess);
      } else if (Number(tourSuccess.booking.totalAmount) == 0) {
        if (tourSuccess.guest === null) {
          setBookingDetails(tourSuccess);
          setShowSuccessPopup(true);
        } else {
          setBookingDetails(tourSuccess);

          setPopupForGuest(true);
          setShowSuccessPopup(true);
        }
      }
    }
    
  }, [tourSuccess]);

 

  useEffect(() => {
    if (tourError !== undefined) {
      if (alert(`${tourError}`)) {
        clearBackendErrorAsync();
        setCounter(0);
        setChecked(false);
      } else {
        clearBackendErrorAsync();
        setCounter(0);
        setChecked(false);
      }
    }
  }, [tourError]);

  const razorPayCall = (res) => {
    console.log("inside razorpay");
    //console.log("userdet", userDet);
    let options;
    try {
      if (userDet !== undefined && res.guest === null) {
        options = {
          // key: "rzp_live_6i9IJT8pW0sluE",
          key: process.env.RAZZLE_APP_RAZORPAYKEY,
          name: "Followgoa",
          description: "Unique journey",
          order_id: res.booking.razorPayOrderId,
          amount: res.booking.totalAmount * 100,
          handler: async (response) => {
            try {
              // console.log("res -> ", response);
              //alert("Payment succesfull!");
              if (
                response &&
                response.razorpay_payment_id !== undefined &&
                response.razorpay_payment_id !== null
              ) {
                setSuccess(true);
              }
              setShowSuccessPopup(true);
              setChecked(false);
              refreshBookingState();
              // this.props.history.push(`/redirect/${res.data.id}`, {
              //   status: "Success",
              // });
              //alert(`Payment Success. Payment Id ${paymentId}`);
            } catch (err) {
              alert("Error --->", err);
              setChecked(false);
              refreshBookingState();
              // this.props.history.push(`/redirect/${res.data.id}`, {
              //   status: "Failure",
              // });
              ////console.log("err-res", err);
              // alert(err);
            }
          },

          prefill: {
            //   name: user.displayName,
            email: userDet.email,
            contact: userDet.mobile,
          },
          theme: {
            color: "#FDD72A",
          },
          modal: {
            ondismiss: function () {
              // console.log("Checkout form closed");
              setChecked(false);
              refreshBookingState();
            },
          },
        };
      } else {
        if (userDet === undefined && res.guest !== null) {
          options = {
            // key: "rzp_live_6i9IJT8pW0sluE",
            key: process.env.RAZZLE_APP_RAZORPAYKEY,
            name: "Followgoa",
            description: "Unique journey",
            order_id: res.booking.razorPayOrderId,
            amount: res.booking.totalAmount * 100,
            handler: async (response) => {
              try {
                // console.log("res -> ", response);
                //alert("Payment succesfull!");\

                setPopupForGuest(true);
                setShowSuccessPopup(true);
                setChecked(false);
                refreshBookingState();
                // this.props.history.push(`/redirect/${res.data.id}`, {
                //   status: "Success",
                // });
                //alert(`Payment Success. Payment Id ${paymentId}`);
              } catch (err) {
                alert("Error --->", err);
                setChecked(false);
                refreshBookingState();
                // this.props.history.push(`/redirect/${res.data.id}`, {
                //   status: "Failure",
                // });
                ////console.log("err-res", err);
                // alert(err);
              }
            },

            prefill: {
              //   name: user.displayName,
              email: res.guest.email,
              contact: res.guest.mobile,
            },
            theme: {
              color: "#FDD72A",
            },
            modal: {
              ondismiss: function () {
                // console.log("Checkout form closed");
                setChecked(false);
                refreshBookingState();
              },
            },
          };
        }
      }

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (e) {
      console.log("err for razor", e);
    }
  };

  const handleDropdown = (e) => {
    // console.log("e ", e);
    setDateForBackend(e.value);
  };

  const makeAbsolute = (num) => {
    let number = Number(num);
    // let amount = new BN(number);
    let fn = new BigNumber(number);
    let rounded = fn.dp(0, BigNumber.ROUND_HALF_UP);

    return rounded.toNumber();
  };

  function checkProperties(o) {
    const allTrue = Object.values(o).some((value) => value === "");
    return allTrue;
  }

  return (
    <div id="TourBooking">
      <Navbar />

      {(eventLoading || tourBookLoading || validateCouponCodeLoading) && (
        <Loader />
      )}
      <div className="banner-section">
        {eventDetails !== undefined && eventDetails.banner !== null && (
          <img src={banner} className="bannerbgimg" />
        )}
      </div>
      <Container>
        <Row>
          <Col lg={8} className="details-div">
            <div className="booking-details">
              <div className="details">
                <div className="top">
                  <div className="event-name">
                    <span>
                      {" "}
                      {eventDetails !== undefined &&
                        title !== undefined &&
                        title}
                    </span>
                  </div>
                  <div className="reference-id">
                    {eventDetails !== undefined &&
                      eventDetails.tourRef !== null && (
                        <span>Reference ID: {tourRef}</span>
                      )}
                  </div>
                </div>
                <hr />
                <div className="event-block">
                  {eventDetails !== undefined && location !== undefined && (
                    <p className="location">
                      <LocationIcon className="loc-icon" /> {location}
                    </p>
                  )}
                  {eventDetails !== undefined &&
                    eventDetails.googleMapLink !== null && (
                      <div className="leftalign">
                        <a
                          className="viewlocation"
                          href={`${eventDetails.googleMapLink}`}
                          target="_blank"
                        >
                          View Location
                        </a>
                      </div>
                    )}
                  <span>
                    {/* <span>
                      <span className="details-text">
                        <CalendarIcon className="calendar-icon" />{" "}
                        {moment(startDate).format("dddd, MMMM Do YYYY")}{" "}
                        <ClockIcon className="clock-icon" />{" "}
                        {moment(startDate).format("h:mm A")}{" "}
                      </span>
                      <span className="details-text-to">To</span>
                      <span className="details-text">
                        <CalendarIcon className="calendar-icon" />{" "}
                        {moment(endDate).format("dddd, MMMM Do YYYY")}{" "}
                        <ClockIcon className="clock-icon" />{" "}
                        {moment(endDate).format("h:mm A")}{" "}
                      </span>
                    </span> */}
                  </span>
                </div>
                <hr />

                <div className="price-block">
                  {eventDetails !== undefined &&
                    eventDetails.amount !== null && (
                      <span className="title">₹{amount} onwards</span>
                    )}{" "}
                </div>
              </div>
            </div>
          </Col>
          {dropdownDates !== undefined && dropdownDates.length > 0 ? (
            <Col lg={4} className="d-none d-lg-block">
              <div className="payment-div">
                <div className="detail mb-2">
                  <span>Select Date:</span>
                </div>
                <Form.Group className="select-dp">
                  {/* <label className="detail">Select Date : </label> */}
                  {/* <Select
                    placeholder="Select Date"
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                    options={dropdownDates}
                    value={showValue}
                    onChange={(label) => {
                      setShowValue(label);
                      handleDropdown(label);
                    }}
                  /> */}
                  <div className="test d-flex">
                    <DatePicker
                      showIcon
                      className="departure-date"
                      selected={selectedDate}
                      onChange={handleDateChange}
                      // dateFormat="yyyy-MM-dd h:mm aa"
                      // timeFormat="HH:mm aa"
                      // timeCaption="time"
                      dateFormat="MMMM d, yyyy"
                      highlightDates={highlightDates()}
                      renderDayContents={renderDayContents}
                      minDate={new Date()}
                      filterDate={filterDate}
                      placeholderText="Select Date"
                    />
                    {/* <input
                   className="departure-date time" 
                    type="text"
                    value={departureTime}
                    readOnly
                  /> */}
                  </div>
                </Form.Group>
                {departureTime !== undefined && departureTime.length > 0 && (
                  <div className="detail">
                    <span className="gst">
                      Tour time :
                      {departureTime && departureTime.length > 1
                        ? departureTime.map((time) => (
                            <Form.Check
                              type="radio"
                              name="dept"
                              value={time.timeVal}
                              id={time.idOfTime}
                              label={time.timeVal}
                              checked={time.timeVal == selectedTime}
                              onChange={(e) => {
                                setSelectedTime(e.target.value);
                                handleTimeChange(e);
                              }}
                            />
                          ))
                        : departureTime &&
                          departureTime.map((time) => time.timeVal)}
                    </span>
                  </div>
                )}
                {/* <div className="detail mt-2">
                  <span className="noofguest">
                    Number of Travellers :{" "}
                    {eventDetails !== undefined && eventDetails.total}
                  </span>
                </div> */}
                {eventDetails !== undefined &&
                  eventDetails.gstPercentage !== null &&
                  eventDetails.gstPercentage !== "0.00" && (
                    <div className="detail">
                      <span className="gst">
                        GST :
                        {
                          eventDetails.gstPercentage
                          // amount * counter
                        }
                        %
                      </span>
                    </div>
                  )}
                <div className="detail">
                  <span className="dueamt1">
                    Amount due : ₹
                    {amount !== undefined && counter > 0 ? amount * counter : 0}
                    {/* {amount * counter + Number(eventDetails.gstPercentage)} */}
                  </span>
                </div>
                {amount !== undefined &&
                  counter > 0 &&
                  eventDetails !== undefined &&
                  eventDetails.gstPercentage !== null &&
                  eventDetails.gstPercentage !== "0.00" && (
                    <div className="detail">
                      <span className="dueamt">
                        Total Amount{" "}
                        <span className="addition">(GST + Amount due)</span> : ₹
                        {makeAbsolute(
                          (
                            (amount *
                              counter *
                              Number(eventDetails.gstPercentage)) /
                              100 +
                            amount * counter
                          ).toFixed(2)
                        )}
                        {/* {amount * counter + Number(eventDetails.gstPercentage)} */}
                      </span>
                    </div>
                  )}
                <Form.Group className="input-div">
                  <label className="maintxt">Travellers :</label>{" "}
                  <div className="counter">
                    <CustomButton
                      type="button"
                      name="-"
                      filled={true}
                      primary={true}
                      onClick={decrease}
                      classname={`counter-btn ${
                        counter === 0 ? "btndisable" : ""
                      }`}
                      disable={counter ==0}
                    />
                    <div className="counter_output">
                      <span>{counter}</span>
                    </div>
                    <CustomButton
                      type="button"
                      name="+"
                      filled={true}
                      primary={true}
                      onClick={increase}
                      classname={`counter-btn ${
                        counter === maxGuest ? "btndisable" : ""
                      }`}
                      disable={counter==maxGuest}
                    />
                  </div>
                </Form.Group>

                <div className="coupon-section">
                  <div className="coupon-div">
                    <InputField
                      placeholder={"Enter Coupon code"}
                      type="text"
                      classname="coupon-field"
                      value={coupon}
                      onChange={(event) => {
                        setCoupon(event.target.value);
                      }}
                    />
                    <div className="apply-btn">
                      <CustomButton
                        type="button"
                        name={codeEntered ? "Clear" : "Apply"}
                        filled={true}
                        primary={true}
                        classname="pay-btn"
                        // onClick={() => {
                        //   if (

                        //     userLoggedIn !== undefined
                        //   ) {
                        //     if (
                        //       coupon !== undefined &&
                        //       coupon !== "" &&
                        //       !codeEntered
                        //     ) {
                        //       ValidateCouponCodeAsync({
                        //         eventId:
                        //           id !== undefined && isNumber(id)
                        //             ? Number(id)
                        //             : eveId,
                        //         couponCode: coupon,
                        //         guestCalling: false,
                        //       });
                        //       setCodeEntered(true);
                        //     } else {
                        //       handleCouponCodeClear();
                        //       setCodeEntered(false);
                        //     }
                        //   } else {
                        //     if (coupon !== undefined && coupon !== "" &&
                        //     !codeEntered) {
                        //       ValidateCouponCodeAsync({
                        //         eventId:
                        //           id !== undefined && isNumber(id)
                        //             ? Number(id)
                        //             : eveId,
                        //         couponCode: coupon,
                        //         guestCalling: true,
                        //       });
                        //       setCodeEntered(true);
                        //     } else {
                        //       handleCouponCodeClear();
                        //       setCodeEntered(false);
                        //     }
                        //   }
                        // }}
                        onClick={() => {
                          console.log(userLoggedIn, !codeEntered);
                          if (userLoggedIn !== undefined) {
                            if (
                              coupon !== undefined &&
                              coupon !== "" &&
                              !codeEntered
                            ) {
                              ValidateCouponCodeAsync({
                                eventId:
                                  id !== undefined && isNumber(id)
                                    ? Number(id)
                                    : eveId,
                                couponCode: coupon,
                                guestCalling: false,
                              });
                              setCodeEntered(true);
                            } else {
                              handleCouponCodeClear();
                              setCodeEntered(false);
                            }
                          } else {
                            if (
                              coupon !== undefined &&
                              coupon !== "" &&
                              !codeEntered
                            ) {
                              ValidateCouponCodeAsync({
                                eventId:
                                  id !== undefined && isNumber(id)
                                    ? Number(id)
                                    : eveId,
                                couponCode: coupon,
                                guestCalling: true,
                              });
                              setCodeEntered(true);
                            } else {
                              handleCouponCodeClear();
                              setCodeEntered(false);
                            }
                          }
                        }}
                        disable={!disableButton}
                      />
                    </div>
                  </div>
                  <p className="errorText">
                    {validateCouponCodeError !== undefined
                      ? validateCouponCodeError
                      : ""}
                  </p>
                  <div className="amount-div">
                    <div className="amount-text">
                      <span className="discount-text">
                        Discount: ₹
                        {validateCouponCodeResult !== undefined
                          ? validateCouponCodeResult.type === "percent"
                            ? validateCouponCodeResult.maximumDiscount !==
                                undefined &&
                              validateCouponCodeResult.maximumDiscount !==
                                null &&
                              validateCouponCodeResult.maximumDiscount !== 0
                              ? (
                                  (getTotalValue() *
                                    validateCouponCodeResult.value) /
                                  100
                                ).toFixed(2) <=
                                validateCouponCodeResult.maximumDiscount
                                ? (
                                    (getTotalValue() *
                                      validateCouponCodeResult.value) /
                                    100
                                  ).toFixed(2)
                                : validateCouponCodeResult.maximumDiscount
                              : (
                                  (getTotalValue() *
                                    validateCouponCodeResult.value) /
                                  100
                                ).toFixed(2)
                            : Number(validateCouponCodeResult.value) >
                              Number(getTotalValue())
                            ? amount !== undefined && counter > 0
                              ? amount * counter
                              : 0
                            : validateCouponCodeResult.value
                          : 0}
                      </span>
                    </div>
                    <div className="amount-text">
                      <span>
                        Total Payable: ₹
                        {validateCouponCodeResult !== undefined
                          ? validateCouponCodeResult.type === "percent"
                            ? validateCouponCodeResult.maximumDiscount !==
                                undefined &&
                              validateCouponCodeResult.maximumDiscount !==
                                null &&
                              validateCouponCodeResult.maximumDiscount !== 0
                              ? makeAbsolute(
                                  (
                                    (getTotalValue() *
                                      validateCouponCodeResult.value) /
                                    100
                                  ).toFixed(2)
                                ) <= validateCouponCodeResult.maximumDiscount
                                ? makeAbsolute(
                                    (
                                      getTotalValue() -
                                      (
                                        (getTotalValue() *
                                          validateCouponCodeResult.value) /
                                        100
                                      ).toFixed(2)
                                    ).toFixed(2)
                                  )
                                : makeAbsolute(
                                    (
                                      getTotalValue() -
                                      validateCouponCodeResult.maximumDiscount
                                    ).toFixed(2)
                                  )
                              : makeAbsolute(
                                  (
                                    getTotalValue() -
                                    (
                                      (getTotalValue() *
                                        validateCouponCodeResult.value) /
                                      100
                                    ).toFixed(2)
                                  ).toFixed(2)
                                )
                            : Number(validateCouponCodeResult.value) >
                              Number(getTotalValue())
                            ? makeAbsolute(
                                (
                                  (amount *
                                    counter *
                                    Number(eventDetails.gstPercentage)) /
                                  100
                                ).toFixed(2)
                              )
                            : makeAbsolute(
                                (
                                  getTotalValue() -
                                  validateCouponCodeResult.value
                                ).toFixed(2)
                              )
                          : makeAbsolute(getTotalValue())}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="check-div">
                  <Form.Check
                    // label={"Agree to terms and conditions"}
                    label={
                      <Link to="/terms-conditions" onClick={() => history.push('/terms-conditions')}>
                        Agree to terms and conditions
                      </Link>
                    }
                    name="category"
                    checked={checked}
                    onClick={(e) => handleCheckButton(e)}
                  />
                </div>
                <div className="btn-div">
                  <CustomButton
                    type="button"
                    name={
                      counter !== 0 && amount === 0
                        ? "Register"
                        : "Continue to Payment"
                    }
                    filled={true}
                    primary={true}
                    classname="pay-btn"
                    onClick={() => {
                      console.log("checkk", checkProperties(person));

                      if (userLoggedIn !== undefined) {
                        //      console.log("clicked1");
                        handlePaymentButtonCall(false);
                      } else if (
                        userLoggedIn === undefined &&
                        _.isEmpty(person) === false &&
                        checkProperties(person) === false &&
                        person.email !== "" &&
                        emailPattern.test(person.email) === true &&
                        person.mobile !== "" &&
                        re.test(person.mobile) === true
                      ) {
                        // console.log("clicked2");
                        // console.log("inside else if 1");
                        handlePaymentButtonCall(true);
                      } else {
                        if (
                          person.email === "" ||
                          emailPattern.test(person.email) === false
                        ) {
                          //  console.log("clicked3");
                          alert("Please enter valid email");
                        } else if (
                          person.mobile === "" ||
                          re.test(person.mobile) === false
                        ) {
                          //  console.log("clicked4");
                          alert("Please enter valid mobile number !");
                        } else if (person.userName === "") {
                          alert("Please enter you name !");
                        }
                        // else {

                        //   alert("Please fill all the fields to continue !");
                        // }
                      }
                    }}
                  />
                </div>
              </div>
            </Col>
          ) : (
            <Col lg={4} className="d-none d-lg-block">
              <div className="payment-div noseat">
                <div className="noseattext mb-2">
                  <span>Sorry, No tickets available !</span>
                </div>
              </div>
            </Col>
          )}
        </Row>
        {userLoggedIn === undefined && (
          <Row>
            <Col
              lg={8}
              className={
                dropdownDates !== undefined && dropdownDates.length > 0
                  ? ""
                  : "bookform2"
              }
            >
              <BookingForm guestDet={person} setGuestDet={handleGuest} />
            </Col>
          </Row>
        )}
        <Row className="d-lg-none">
          {dropdownDates !== undefined && dropdownDates.length > 0 ? (
            <Col lg={4}>
              <div className="payment-div">
                <div className="detail mb-2">
                  <span>Select Date:</span>
                </div>
                <Form.Group className="select-dp">
                  {/* <label className="detail">Select Date : </label> */}
                  {/* <Select
                    placeholder="Select Date"
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                    options={dropdownDates}
                    value={showValue}
                    onChange={(label) => {
                      setShowValue(label);
                      handleDropdown(label);
                    }}
                  /> */}
                  <DatePicker
                    showIcon
                    className="departure-date"
                    selected={selectedDate}
                    onChange={handleDateChange}
                    timeFormat="HH:mm aa"
                    // timeCaption="time"
                    dateFormat="MMMM d, yyyy"
                    highlightDates={highlightDates()}
                    renderDayContents={renderDayContents}
                    minDate={new Date()}
                    filterDate={filterDate}
                    placeholderText="Select Date"
                  />
                  {/* <input
                   className="departure-date time"
                    type="text"
                    value={departureTime}
                    readOnly
                  /> */}
                </Form.Group>
                {departureTime !== undefined && departureTime.length > 0 && (
                  <div className="detail">
                    <span className="gst">
                      Tour time :
                      {departureTime && departureTime.length > 1
                        ? departureTime.map((time) => (
                            <Form.Check
                              type="radio"
                              name="departure"
                              value={time.timeVal}
                              id={time.idOfTime}
                              label={time.timeVal}
                              checked={time.timeVal == selectedTime}
                              onChange={(e) => {
                                setSelectedTime(e.target.value);
                                handleTimeChange(e);
                              }}
                            />
                          ))
                        : departureTime &&
                          departureTime.map((time) => time.timeVal)}
                    </span>
                  </div>
                )}
                {/* <div className="detail mt-2">
                  <span className="noofguest">
                    Number of Travellers :{" "}
                    {eventDetails !== undefined && eventDetails.total}
                  </span>
                </div> */}
                {eventDetails !== undefined &&
                  eventDetails.gstPercentage !== null &&
                  eventDetails.gstPercentage !== "0.00" && (
                    <div className="detail">
                      <span className="gst">
                        GST :
                        {
                          eventDetails.gstPercentage
                          // amount * counter
                        }
                        %
                      </span>
                    </div>
                  )}
                <div className="detail">
                  <span className="dueamt1">
                    Amount due : ₹
                    {amount !== undefined && counter > 0 ? amount * counter : 0}
                    {/* {amount * counter + Number(eventDetails.gstPercentage)} */}
                  </span>
                </div>
                {amount !== undefined &&
                  counter > 0 &&
                  eventDetails !== undefined &&
                  eventDetails.gstPercentage !== null &&
                  eventDetails.gstPercentage !== "0.00" && (
                    <div className="detail">
                      <span className="dueamt">
                        Total Amount{" "}
                        <span className="addition">(GST + Amount due)</span> : ₹
                        {(
                          (amount *
                            counter *
                            Number(eventDetails.gstPercentage)) /
                            100 +
                          amount * counter
                        ).toFixed(2)}
                        {/* {amount * counter + Number(eventDetails.gstPercentage)} */}
                      </span>
                    </div>
                  )}
                <Form.Group className="input-div">
                  <label className="maintxt">Travellers :</label>{" "}
                  <div className="counter">
                    <CustomButton
                      type="button"
                      name="-"
                      filled={true}
                      primary={true}
                      onClick={decrease}
                      classname={`counter-btn ${
                        counter === 0 ? "btndisable" : ""
                      }`}
                      disable={counter ==0}
                    />
                    <div className="counter_output">
                      <span>{counter}</span>
                    </div>
                    <CustomButton
                      type="button"
                      name="+"
                      filled={true}
                      primary={true}
                      onClick={increase}
                      classname={`counter-btn ${
                        counter == maxGuest ? "btndisable" : ""
                      }`}
                      disable={counter == maxGuest}
                    />
                  </div>
                </Form.Group>

                <div className="coupon-section">
                  <div className="coupon-div">
                    <InputField
                      placeholder={"Enter Coupon code"}
                      type="text"
                      classname="coupon-field"
                      value={coupon}
                      onChange={(event) => setCoupon(event.target.value)}
                    />
                    <div className="apply-btn">
                      <CustomButton
                        type="button"
                        name="Apply"
                        filled={true}
                        primary={true}
                        classname="pay-btn"
                        onClick={() => {
                          if (
                            coupon !== undefined &&
                            coupon !== "" &&
                            userLoggedIn !== undefined
                          ) {
                            ValidateCouponCodeAsync({
                              eventId:
                                id !== undefined && isNumber(id)
                                  ? Number(id)
                                  : eveId,
                              couponCode: coupon,
                              guestCalling: false,
                            });
                          } else {
                            if (coupon !== undefined && coupon !== "") {
                              ValidateCouponCodeAsync({
                                eventId:
                                  id !== undefined && isNumber(id)
                                    ? Number(id)
                                    : eveId,
                                couponCode: coupon,
                                guestCalling: true,
                              });
                            }
                          }
                        }}
                        disable={!codeEntered}
                      />
                    </div>
                  </div>
                  <p className="errorText">
                    {validateCouponCodeError !== undefined
                      ? validateCouponCodeError
                      : ""}
                  </p>
                  <div className="amount-div">
                    <div className="amount-text">
                      <span className="discount-text">
                        Discount: ₹
                        {validateCouponCodeResult !== undefined
                          ? validateCouponCodeResult.type === "percent"
                            ? validateCouponCodeResult.maximumDiscount !==
                                undefined &&
                              validateCouponCodeResult.maximumDiscount !==
                                null &&
                              validateCouponCodeResult.maximumDiscount !== 0
                              ? (
                                  (getTotalValue() *
                                    validateCouponCodeResult.value) /
                                  100
                                ).toFixed(2) <=
                                validateCouponCodeResult.maximumDiscount
                                ? (
                                    (getTotalValue() *
                                      validateCouponCodeResult.value) /
                                    100
                                  ).toFixed(2)
                                : validateCouponCodeResult.maximumDiscount
                              : (
                                  (getTotalValue() *
                                    validateCouponCodeResult.value) /
                                  100
                                ).toFixed(2)
                            : Number(validateCouponCodeResult.value) >
                              Number(getTotalValue())
                            ? amount !== undefined && counter > 0
                              ? amount * counter
                              : 0
                            : validateCouponCodeResult.value
                          : 0}
                      </span>
                    </div>
                    <div className="amount-text">
                      <span>
                        Total Payable: ₹
                        {validateCouponCodeResult !== undefined
                          ? validateCouponCodeResult.type === "percent"
                            ? validateCouponCodeResult.maximumDiscount !==
                                undefined &&
                              validateCouponCodeResult.maximumDiscount !==
                                null &&
                              validateCouponCodeResult.maximumDiscount !== 0
                              ? (
                                  (getTotalValue() *
                                    validateCouponCodeResult.value) /
                                  100
                                ).toFixed(2) <=
                                validateCouponCodeResult.maximumDiscount
                                ? (
                                    getTotalValue() -
                                    (
                                      (getTotalValue() *
                                        validateCouponCodeResult.value) /
                                      100
                                    ).toFixed(2)
                                  ).toFixed(2)
                                : (
                                    getTotalValue() -
                                    validateCouponCodeResult.maximumDiscount
                                  ).toFixed(2)
                              : (
                                  getTotalValue() -
                                  (
                                    (getTotalValue() *
                                      validateCouponCodeResult.value) /
                                    100
                                  ).toFixed(2)
                                ).toFixed(2)
                            : Number(validateCouponCodeResult.value) >
                              Number(getTotalValue())
                            ? (
                                (amount *
                                  counter *
                                  Number(eventDetails.gstPercentage)) /
                                100
                              ).toFixed(2)
                            : (
                                getTotalValue() - validateCouponCodeResult.value
                              ).toFixed(2)
                          : getTotalValue()}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="check-div">
                  <Form.Check
                    // label={"Agree to terms and conditions"}
                    label={
                      <Link to="/terms-conditions" onClick={() => history.push('/terms-conditions')}>
                        Agree to terms and conditions
                      </Link>
                    }
                    name="category"
                    checked={checked}
                    onClick={(e) => handleCheckButton(e)}
                  />
                </div>
                <div className="btn-div">
                  <CustomButton
                    type="button"
                    name={
                      counter !== 0 && amount === 0
                        ? "Register"
                        : "Continue to Payment"
                    }
                    filled={true}
                    primary={true}
                    classname="pay-btn"
                    onClick={() => {
                      if (userLoggedIn !== undefined) {
                        console.log("userlogged", userLoggedIn);
                        handlePaymentButtonCall(false);
                      } else if (
                        userLoggedIn === undefined &&
                        _.isEmpty(person) === false &&
                        Object.keys(person).length > 2
                      ) {
                        handlePaymentButtonCall(true);
                      } else {
                        if (
                          person.email === "" &&
                          emailPattern.test(person.email) === false
                        ) {
                          alert("Please enter valid email");
                        } else if (
                          person.mobile === "" &&
                          re.test(person.mobile) === false
                        ) {
                          alert("Please enter valid mobile number !");
                        } else {
                          alert("Please fill all the fields to continue !");
                        }
                      }
                    }}
                  />
                </div>
              </div>
            </Col>
          ) : (
            <Col lg={4}>
              <div className="payment-div noseat">
                <div className="noseattext mb-2">
                  <span>Sorry ! No tickets available</span>
                </div>
              </div>
            </Col>
          )}
        </Row>
        {/* <Row>
          <Col>
            <div className="terms">
              <div className="terms-title">
                <span>Terms and Conditions</span>
              </div>
              <p className="desc">
                Followgoa is an authorised Agent of Travel Talk. Please
                familiarise yourself with the Travel Talk{" "}
                <a>payment, cancellation and refund policies</a> and Followgoa{" "}
                <a>Terms & Conditions</a>. Followgoa will charge you in the
                stated currency and we do not charge any booking fees.
              </p>
            </div>
          </Col>
        </Row> */}
        <BookingSuccessPopup
          bookingDetails={bookingDetails}
          setBookingDetails={setBookingDetails}
          show={showSuccessPopup}
          setShowModal={setShowSuccessPopup}
          forGuest={popupForGuest}
          successOrFail={success}
          setSucc={setSuccess}
          closeForGuest={(e) => setPopupForGuest(e)}
        />
      </Container>
      <div className="footer-div">
        <MainFooter />
      </div>
    </div>
  );
}
const mapStateToProps = ({ user = {}, event = {} }) => {
  const eventLoading = _.get(event, "eventDetailsLoading", false);
  const eventDetails = _.get(event, "eventDetails", []);
  const eventError = _.get(event, "eventDetailsError", undefined);
  const faqsLoading = _.get(event, "faqsLoading", false);
  const faqs = _.get(event, "faqs", []);
  const faqsError = _.get(event, "faqsError", undefined);
  const userLoggedIn = _.get(user, "signedIn", undefined);
  const eventItinerariesLoading = _.get(
    event,
    "eventItinerariesLoading",
    false
  );
  const eventItineraries = _.get(event, "eventItineraries", undefined);
  const eventItinerariesError = _.get(
    event,
    "eventItinerariesError",
    undefined
  );
  const villaDetLoading = _.get(event, "villaDetailLoading", false);
  const villaDet = _.get(event, "villaDetails", undefined);
  const villaError = _.get(event, "villaDetailsError", undefined);
  const villaFaqLoading = _.get(event, "villaFacilityLoading", false);
  const villaFaq = _.get(event, "villaFacility", undefined);
  const villaFaqError = _.get(event, "villaFacilityError", undefined);
  const villaBookingLoading = _.get(event, "villaBookLoading", false);
  const villaBookingSucc = _.get(event, "villaBooking", undefined);
  const villaBookError = _.get(event, "villaBookingError", undefined);
  const userDet = _.get(user, "userDetails", undefined);
  const tourBookLoading = _.get(event, "tourBookingLoading", false);
  const tourSuccess = _.get(event, "tourBooking", undefined);
  const tourError = _.get(event, "tourBookingError", undefined);

  const validateCouponCodeLoading = _.get(
    event,
    "validateCouponCodeLoading",
    false
  );
  const validateCouponCodeResult = _.get(
    event,
    "validateCouponCode",
    undefined
  );
  const validateCouponCodeError = _.get(
    event,
    "validateCouponCodeError",
    undefined
  );

  return {
    eventLoading,
    eventDetails,
    eventError,
    faqsLoading,
    faqs,
    userDet,
    faqsError,
    userLoggedIn,
    eventItinerariesLoading,
    eventItineraries,
    eventItinerariesError,
    villaDetLoading,
    villaDet,
    villaError,
    villaFaqLoading,
    villaFaq,
    villaFaqError,
    villaBookingLoading,
    villaBookingSucc,
    villaBookError,
    tourBookLoading,
    tourSuccess,
    tourError,
    validateCouponCodeLoading,
    validateCouponCodeResult,
    validateCouponCodeError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  GetEventDetail: (data) =>
    dispatch({ type: "getEventDetailsCalled", payload: data }),
  GetFaqs: (data) => dispatch({ type: "getFaqsCalled", payload: data }),
  clearBackendErrorAsync: () => dispatch(clearBackendError()),
  eventItinerariesCalled: (data) =>
    dispatch({ type: "eventItinerariesCalled", payload: data }),
  GetVillaDetails: (data) =>
    dispatch({ type: "villaDetailsSagaCalled", payload: data }),
  GetVillaFacilities: (data) =>
    dispatch({ type: "villaFacilitySagaCalled", payload: data }),
  BookVillaAsync: (data) =>
    dispatch({ type: "bookVillasagaCalled", payload: data }),
  refreshBookingState: () => dispatch(resetTourBooking()),
  TourBookAsync: (data) =>
    dispatch({ type: "tourBookingSagaCalled", payload: data }),

  ValidateCouponCodeAsync: (data) =>
    dispatch({ type: "validateCouponCodeSagaCalled", payload: data }),
  clearCouponCodeAsync: () => dispatch(clearCouponResult()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TourBooking);
