import React, { useEffect } from "react";
import AboutUs from "@features/about-us/AboutUs";
import ComingSoon from "@features/coming-soon/ComingSoon";
import ForgotPassword from "@features/forgot-password/ForgotPassword";
import HomePage from "@features/home-page/HomePage";
import Login from "@features/login/Login";
import OperatorSignup from "@features/operator-signup/OperatorSignup";
import SearchPage from "@features/search-page/SearchPage";
import SearchPageProperties from "@features/search-properties/SearchPageProperties";
import SearchLuxuryCar from "@features/search-luxury-car/SearchLuxuryCar";
import SignUp from "@features/signup/SignUp";
import { Provider, useSelector } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import 'rc-slider/assets/index.css';
import EventDetails from "@features/event-detail/EventDetails";
import BookingPage from "@features/booking-page/BookingPage";
import Profile from "@features/profile/Profile";
import ChangePassword from "@features/change-password/ChangePassword";
import UserVerification from "@features/user-verification/UserVerification";
import EmailVerification from "@features/email-verification/EmailVerification";
import EditProfile from "@features/edit-profile/EditProfile";
import BookingDetails from "@features/booking-details/BookingDetails";
import BookingHistory from "@features/booking-history/BookingHistory";
import ResetPassword from "@features/reset-password/ResetPassword";
import ResetPasswordSuccess from "@features/reset-password-success/ResetPasswordSuccess";
import PropertyDetails from "@features/property-details/PropertyDetails";
import PropertyBooking from "@features/property-booking/PropertyBooking";
import TourBooking from "@features/tour-booking/TourBooking";
import TourDetails from "@features/tour-details/TourDetails";
import TermsandConditions from "@features/terms-conditions/TermsandConditions";
import TermsandConditionsForEvents from "@features/terms-and-conditions-for-events/TermsConditionsForEvents";
import PrivacyPolicy from "@features/privacy-policy/PrivacyPolicy";
import RefundPolicy from "@features/refund-policy/RefundPolicy";
import ContactUs from "@features/contact-us/ContactUs";
import "./App.scss";
import BookingDetailForGuest from "@features/BookingDetailsForGuest/BookingDetailForGuest";
import WhatsAppLayout from "@components/layout/WhatsappLayout";
import LuxuryCarsDetails from "@features/luxuryCars-details/LuxuryCarsDetails";
import LuxuryCarsBooking from "@features/luxuryCars-booking/LuxuryCarsBooking";

function App() {
  const PrivateRoute = ({ component: Component, ...rest }) => {
    const auth = useSelector((state) => state.user);

    // console.log("Auth -> ", auth);

    let Authorized = auth && auth.signedIn && auth.signedIn.accessToken;
    // console.log("authorized", Authorized);
    return (
      <Route
        {...rest}
        render={(props) =>
          Authorized ? <Component {...props} /> : <Redirect to="/" />
        }
      />
    );
  };
  useEffect(() => {
    const isCookieEnabled = navigator.cookieEnabled;
    if (!isCookieEnabled) {
      alert("Please enable cookies to continue!");
    }

    // console.log(isCookieEnabled);
  }, []);

  return (
    <WhatsAppLayout> 
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route exact path="/comingsoon" component={ComingSoon} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/signup" component={SignUp} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/aboutus" component={AboutUs} />
      <Route exact path="/operator-register" component={OperatorSignup} />
      <Route exact path="/allevents" component={SearchPage} />
      <Route exact path="/searchPropertiesTours" component={SearchPageProperties} />
      <Route exact path="/searchLuxuryCars" component={SearchLuxuryCar} />
      <Route exact path="/events/:id" component={EventDetails} />
      <Route exact path="/events" component={EventDetails} />
      <Route exact path="/bookevent/:id" component={BookingPage} />
      <Route exact path="/bookevent/" component={BookingPage} />
      <PrivateRoute exact path="/myprofile" component={Profile} />
      <PrivateRoute exact path="/change-password" component={ChangePassword} />
      <Route exact path="/user-verification" component={UserVerification} />
      <PrivateRoute exact path="/edit-profile" component={EditProfile} />
      <PrivateRoute
        exact
        path="/booking-details/:id"
        component={BookingDetails}
      />
      <Route exact path="/booking-history" component={BookingHistory} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route
        exact
        path="/booking-details-guest/:id"
        component={BookingDetailForGuest}
      />
      <Route
        exact
        path="/reset-password-success"
        component={ResetPasswordSuccess}
      />
      <Route exact path="/email-verification" component={EmailVerification} />
      <Route exact path="/properties/:id" component={PropertyDetails} />
      <Route exact path="/properties/" component={PropertyDetails} />
      <Route exact path="/luxurycar/" component={LuxuryCarsDetails} />
      <Route exact path="/luxurycar/:id" component={LuxuryCarsDetails} />
      <Route exact path="/bookproperty/:id" component={PropertyBooking} />
      <Route exact path="/bookproperty/" component={PropertyBooking} />
      <Route exact path="/bookluxurycar/" component={LuxuryCarsBooking} />
      <Route exact path="/bookluxurycar/:id" component={LuxuryCarsBooking} />
      <Route exact path="/booktour/:id" component={TourBooking} />
      <Route exact path="/booktour/" component={TourBooking} />
      <Route exact path="/tourdetails/:id" component={TourDetails} />
      <Route exact path="/tourdetails/" component={TourDetails} />
      <Route exact path="/terms" component={TermsandConditions} />
      <Route exact path="/terms-conditions" component={TermsandConditionsForEvents} />
      {/* <Route exact path="/terms" component={TermsandConditions} /> */}
      <Route exact path="/privacypolicy" component={PrivacyPolicy} />
      <Route exact path="/refundpolicy" component={RefundPolicy} />
      <Route exact path="/contactus" component={ContactUs} />
    </Switch>
    </WhatsAppLayout> 
  );
}

export default App;
