import React from "react";
import { Row, Col } from "react-bootstrap";
import { ReactComponent as BrandLogo } from "@images/followgoa.svg";
import { ReactComponent as Facebook } from "@images/facebook.svg";
import { ReactComponent as Instagram } from "@images/instagram.svg";
import { ReactComponent as Twitter } from "@images/twitter.svg";
import { ReactComponent as Youtube } from "@images/youtube.svg";
import "@components/footer/MainFooterStyle.scss";

const MainFooter = () => {
  return (
    <div className="MainFooter">
      <footer>
        <div className="brand-logo">
          <BrandLogo />
        </div>
        <div className="learn-more">
          <span className="footer-heading">Know More About Us</span>
          <div className="footer-list">
            <div className="footer-list">
              <a href="/contactus">
                <span>Contact Us</span>
              </a>
            </div>
            <a href="/Aboutus">
              <span>About Us</span>
            </a>
          </div>
        </div>
        <div className="learn-more">
          <span className="footer-heading">Useful Links</span>

          <div className="footer-list">
            <a href="/terms">
              <span>Terms and Conditions</span>
            </a>
          </div>
          <div className="footer-list">
            <a href="/privacypolicy">
              <span>Privacy Policy</span>
            </a>
          </div>
          <div className="footer-list">
            <a href="/refundpolicy">
              <span>Refund Policy</span>
            </a>
          </div>
        </div>

        {/* <div className="ticket">
          <span className="footer-heading">Operator</span>
          <ul className="footer-list">
            <a href="/operator-register">
              <li>Signup as a Operator</li>
            </a>

            <a href="https://operator.followgoa.com/#/signin">
              <li>Signin as a Operator</li>
            </a>
          </ul>
        </div> */}

        {/* <div className="contact-us">
          <span className="footer-heading">Contact Us</span>
          <ul className="footer-list">
            <li className="list">
              Hotel Reservation:<span className="contact">123-456-789</span>
            </li>
            <li className="list">
              Ticket Office:<span className="contact">123-456-78</span>
            </li>
          </ul>
        </div> */}
        <div className="connect">
          <span className="footer-heading">Connect</span>
          <div className="footer-list connect-logo">
            <span>
              <a href="https://www.instagram.com/follow_goa/" target="_blank">
                <Instagram className="connect-icon" />
              </a>
            </span>
            <span>
              <a href="https://twitter.com/follow_goa">
                <Twitter className="connect-icon" />
              </a>
            </span>
            <span>
              <a href="https://www.facebook.com/followgoa" target="_blank">
                <Facebook className="connect-icon" />
              </a>
            </span>
          </div>
        </div>
      </footer>
      <hr />
      <Row>
        <Col>
          <h5 className="copyright">© 2023 Followgoa | All Rights Reserved</h5>
        </Col>
      </Row>
    </div>
  );
};

export default MainFooter;
